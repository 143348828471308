<style lang="scss">
.picker-path {
  .el-tabs--left .el-tabs__header.is-left {
    margin-right: 0;
    height: 604px;
  }

  .sp-finder {
    margin-left: 1px;
  }
}
</style>
<template>
  <div class="picker-path">
    <!-- {{ value }} -->
    <el-tabs v-model="tabValue" :tab-position="'left'" v-if="isNotice"> <!-- 订阅通知设置路径 只能设置商品&自定义页 -->
      <el-tab-pane v-if="login_type === 'disti' && admin_type === 'order'" label="商品" name="goods"><!-- 运营端不显示商品-->
        <PickerGoods v-if="tabValue == 'goods'" ref="goods" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="自定义页面" name="custom_page">
        <PickerPages v-if="tabValue == 'custom_page'" ref="custom_page" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="页面" name="link">
        <PickerLink v-if="tabValue == 'link'" ref="link" :value="value" />
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="tabValue" :tab-position="'left'" v-else-if="isDouyin">
      <el-tab-pane label="自定义页面" name="custom_page">
        <PickerPages v-if="tabValue == 'custom_page'" ref="custom_page" :value="value" />
      </el-tab-pane>

      <el-tab-pane label="门店活动" name="offlineactivity">
        <PickerOfflineactivity v-if="tabValue == 'offlineactivity'" ref="offlineactivity" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="页面" name="link">
        <PickerLink v-if="tabValue == 'link'" ref="link" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="弹窗" name="modal">
        <PickerModal v-if="tabValue == 'modal'" ref="modal" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="特殊功能" name="special">
        <PickerSpecial v-if="tabValue == 'special'" ref="special" :value="value" :isDouyin="isDouyin" />
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="tabValue" :tab-position="'left'" v-else-if="isHotWords"> <!-- 关键词设置-设置路径-->
      <el-tab-pane label="商品" name="goods">
        <!-- <PickerGoods v-if="tabValue == 'goods'" ref="goods" :value="value" /> -->
        <PickerGoodsItem v-if="tabValue == 'goods'" ref="goods" :value="value" />

        
      </el-tab-pane>
      <el-tab-pane label="页面" name="link">
        <PickerLink v-if="tabValue == 'link'" ref="link" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="自定义页面" name="custom_page">
        <PickerPages v-if="tabValue == 'custom_page'" ref="custom_page" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="门店活动" name="offlineactivity">
        <PickerOfflineactivity v-if="tabValue == 'offlineactivity'" ref="offlineactivity" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="机型对比" name="compare">
        <PickerCompare v-if="tabValue == 'compare'" ref="compare" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="产品分类" name="classification">
        <PickerClassification v-if="tabValue == 'classification'" ref="classification" :value="value" />
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="tabValue" :tab-position="'left'" v-else>
      <el-tab-pane label="商品" name="goods">
        <PickerGoods v-if="tabValue == 'goods'" ref="goods" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="页面" name="link">
        <PickerLink v-if="tabValue == 'link'" ref="link" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="自定义页面" name="custom_page">
        <PickerPages v-if="tabValue == 'custom_page'" ref="custom_page" :value="value" />
      </el-tab-pane>

      <el-tab-pane label="优惠券" name="coupon">
        <PickerCoupon v-if="tabValue == 'coupon'" ref="coupon" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="直播" name="liverooms">
        <PickerLive v-if="tabValue == 'liverooms'" ref="liverooms" :value="value" />
      </el-tab-pane>

      <el-tab-pane label="弹窗" name="modal">
        <PickerModal v-if="tabValue == 'modal'" ref="modal" :value="value" />
      </el-tab-pane>

      <el-tab-pane label="红包封面" name="redpackage">
        <PickerRedpackage v-if="tabValue == 'redpackage'" ref="redpackage" :value="value" />
      </el-tab-pane>

      <el-tab-pane label="门店活动" name="offlineactivity">
        <PickerOfflineactivity v-if="tabValue == 'offlineactivity'" ref="offlineactivity" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="特殊功能" name="special">
        <PickerSpecial v-if="tabValue == 'special'" ref="special" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="机型对比" name="compare">
        <PickerCompare v-if="tabValue == 'compare'" ref="compare" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="抽奖" name="lottery">
        <PickerLottery v-if="tabValue == 'lottery'" ref="lottery" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="我的" name="member">
        <PickerMember v-if="tabValue == 'member'" ref="member" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="订单兑礼" name="ordertogift">
        <PickerOrderToGift v-if="tabValue == 'ordertogift'" ref="ordertogift" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="订阅消息" name="subscribeMessage">
        <PickerSubscribeMessage v-if="tabValue == 'subscribeMessage'" ref="subscribeMessage" :value="value" />
      </el-tab-pane>
      <el-tab-pane label="产品分类" name="classification">
        <PickerClassification v-if="tabValue == 'classification'" ref="classification" :value="value" />
      </el-tab-pane>

      <!-- <el-tab-pane label="外部小程序" name="other_wxapp">
        <PickerWxApp v-if="tabValue == 'other_wxapp'" ref="other_wxapp" :value="value" />
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from '@/store'
import { cloneDeep } from 'lodash'
import { pickBy } from '@/utils'
import BasePicker from './base'
import PickerGoods from './picker-goods'
import PickerGoodsItem from './picker-goodsitem'
import PickerSaleCategory from './picker-saleCategory'
import PickerCategory from './picker-category'
import PickerTag from './picker-tag'
import PickerArticle from './picker-article'
import PickerPlanting from './picker-planting'
import PickerLink from './picker-link'
import PickerMarketing from './picker-marketing'
import PickerShop from './picker-shop'
import PickerSeckill from './picker-seckill'
import PickerRegactivity from './picker-regactivity'
import PickerPages from './picker-pages'
import PickerLive from './picker-live'
import PickerWxApp from './picker-wxapp'
import PickerCoupon from './picker-coupon'
import PickerModal from './picker-modal'
import PickerRedpackage from './picker-redpackage'
import PickerOfflineactivity from './picker-offlineactivity'
import PickerSpecial from './picker-special'
import PickerCompare from './picker-compare'
import PickerLottery from './picker-lottery'
import PickerMember from './picker-member'
import PickerSubscribeMessage from './picker-subscribeMessage'
import PickerClassification from './picker-classification'
import PickerOrderToGift from './picker-ordertogift'


export default {
  name: 'PickerPath',
  store,
  components: {
    PickerGoods,
    PickerGoodsItem,
    PickerSaleCategory,
    PickerCategory,
    PickerTag,
    PickerArticle,
    PickerPlanting,
    PickerLink,
    PickerMarketing,
    PickerShop,
    PickerSeckill,
    PickerRegactivity,
    PickerPages,
    PickerLive,
    PickerWxApp,
    PickerCoupon,
    PickerModal,
    PickerRedpackage,
    PickerOfflineactivity,
    PickerSpecial,
    PickerCompare,
    PickerLottery,
    PickerMember,
    PickerSubscribeMessage,
    PickerClassification,
    PickerOrderToGift
  },
  // extends: BasePicker,
  config: {
    title: '设置路径'
  },
  props: ['value'],
  data() {
    const { tab } = this.value
    return {
      pathValue: null,
      tabValue: tab || 'goods',
      isDouyin: false,
      isHotWords: false,
      isNotice: false,
      scene: ''
    }
  },
  created() {
    this.pathValue = this.value
    this.isDouyin = this.value?.isDouyin
    this.isHotWords = this.value?.isHotWords
    this.isNotice = this.value?.isNotice
    var parser = window.location.href
    let params = {}
    var query = parser.substring(1);
    var vars = query.split('?')[1].split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (pair[0] == 'scene') {
        this.scene = decodeURIComponent(pair[1])
      }
    }
    if (this.isDouyin && !this.value.tab) {
      this.tabValue = 'custom_page'
    }
    if (this.isNotice && !this.value.tab) {
      if (this.login_type === 'disti' && this.admin_type === 'order') {
        this.tabValue = 'goods'
      } else {
        this.tabValue = 'custom_page'
      }
    }
  },
  computed: {
    ...mapGetters(["login_type", "admin_type"])
  },
  methods: {
    getVal() {
      const { data } = this.$refs[this.tabValue].getVal()
      if (data.length > 0) {
        const arr = this.resolveData(data)
        if (arr.length > 1) {
          let titleArr = []
          arr.forEach(element => {
            titleArr.push({
              linkPage: this.tabValue,
              title: element.title,
              id: element.id,
              ctoken: element.ctoken || '',
              goodsId: element.goodsId || ''
            })
          })
          return titleArr
        } else {
          // const [{ id, title, ctoken = '', goodsId }] = arr
          return {
            linkPage: this.tabValue,
            title: arr[0].title,
            id: arr[0].id,
            ctoken: arr[0].ctoken || '',
            goodsId: arr[0].goodsId || ''
          }
        }
      } else {
        return {
          linkPage: '',
          title: '',
          id: ''
        }
      }
    },
    resolveData(data) {
      const doc = {
        goods: () => {
          let arr = []
          data.forEach(element => {
            arr.push({
              // ...element,
              id: element.itemId || element.item_id,
              title: element.itemName || element.title,
              goodsNo: element.item_bn,
              goodsId: element.goods_id
            })
          })
          return arr
          // return pickBy(data, {
          //   id: 'itemId',
          //   title: 'itemName',
          //   goodsNo:'item_bn',
          //   goodsId: 'goods_id'
          // })
        },
        store: () => {
          return pickBy(data, {
            id: 'distributor_id',
            title: 'name'
          })
        },
        sale_category: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        category: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        tag: () => {
          return pickBy(data, {
            id: 'tag_id',
            title: 'tag_name'
          })
        },
        article: () => {
          return pickBy(data, {
            id: 'article_id',
            title: 'title'
          })
        },
        planting: () => {
          return pickBy(data, {
            id: 'article_id',
            title: 'title'
          })
        },
        link: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        marketing: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        regactivity: () => {
          return pickBy(data, {
            id: 'activity_id',
            title: 'activity_name'
          })
        },
        seckill: () => {
          return pickBy(data, {
            id: 'seckill_id',
            title: 'activity_name'
          })
        },
        custom_page: () => {
          return pickBy(data, {
            id: 'id',
            title: 'page_name'
          })
        },
        liverooms: () => {
          return pickBy(data, {
            id: 'roomid',
            title: 'name'
          })
        },
        other_wxapp: () => {
          return pickBy(data, {
            id: 'wx_external_routes_id',
            title: 'route_info'
          })
        },
        coupon: () => {
          return pickBy(data, {
            id: 'card_id',
            title: 'title'
          })
        },
        modal: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        redpackage: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title',
            ctoken: 'ctoken'
          })
        },
        offlineactivity: () => {
          return pickBy(data, {
            id: 'activity_id',
            title: 'activity_title'
          })
        },
        special: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        compare: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        lottery: () => {
          return pickBy(data, {
            id: 'marketing_id',
            title: 'marketing_title'
          })
        },
        member: () => {
          return pickBy(data, {
            id: 'id',
            title: 'title'
          })
        },
        subscribeMessage() {
          return pickBy(data, {
            id: 'id',
            title: 'msg_name'
          })
        },
        classification: () => {
          return pickBy(data, {
            id: 'id',
            title: 'category_name'
          })
        },
        ordertogift: () => {
          return pickBy(data, {
            id: 'activity_id',
            title: 'activity_title'
          })
        },
      }
      return doc[this.tabValue]()
    }
  }
}
</script>
