<template>
  <div>
    <el-dialog width="600px" :title="title" :visible="visible" @close="closeDialogChange" :close-on-click-modal="false"
      :close-on-press-escape="false" append-to-body destroy-on-close class="export-dialog">
      <div class="export-dialog-subtitle">{{ subtitle }}</div>
      <div v-if="eresets_lists.length > 0">
        <span>导出视图：</span>
        <el-select v-model="eresetsItemId" @change="changeSelect" placeholder="请选择">
          <el-option v-for="item in eresets_lists" :key="item.id" :label="item.presets_name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="export-dialog-body">
        <div class="left">
          <div class="title-box">
            <div class="title">导出字段</div>
            <div class="num">{{ leftkeywords ? leftFilterList.length : leftList.length }}</div>
          </div>
          <div class="export-input">
            <el-input placeholder="请输入内容" v-model="leftkeywords" prefix-icon="el-icon-search" @input="leftInputChange"
              clearable>
            </el-input>
          </div>
          <ul class="left-list">
            <draggable handle='.icon-bars' :animation="300" :scroll="true"
              :v-model="leftkeywords ? leftFilterList : leftList" chosenClass='chosenClass' @end="onEnd">
              <li v-for="(item, index) in leftkeywords ? leftFilterList : leftList " :key="item.name_en"
                class="li-view">
                <div>
                  <i v-if='!leftkeywords' class='iconfont icon-bars'></i>
                  <span>{{ item.name_cn }}</span>
                </div>
                <span v-if="!item.required" class='el-icon-remove' @click="leftDelChange(item, index)"></span>
              </li>
            </draggable>
          </ul>
        </div>
        <div class="right">
          <div class="title-box">
            <div class="title">添加字段</div>
            <div class="num">{{ rightkeywords ? rightFilterList.length : rightList.length }}</div>
          </div>
          <div class="export-input">
            <el-input placeholder="请输入内容" v-model="rightkeywords" prefix-icon="el-icon-search" @input="rightInputChange"
              clearable>
            </el-input>
            <el-button v-if="selectAll" type="text" class="select-all-btn" @click="onSelectAllChange">全选</el-button>
          </div>
          <ul class="right-list">
            <li v-for="(item, index) in rightkeywords ? rightFilterList : rightList" :key="index" class="li-view">
              <div>
                <span>{{ item.name_cn }}</span>
              </div>
              <span class='el-icon-circle-plus' @click="rightAddChange(item, index)"></span>
            </li>
          </ul>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <div>
          <el-button type="primary" plain @click="handleClose">保存设置</el-button>
          <span v-if="eresetsItemId != ''">
            <el-button type="primary" plain @click="updataConfiguration">更新设置</el-button>
            <el-button type="primary" plain @click="deleteConfiguration">删除当前设置</el-button>
          </span>
        </div>
        <div>
          <el-button @click="closeDialogChange">取消</el-button>
          <el-button type="primary" @click="saveDataActionChange">导出</el-button>
        </div>
      </div>
    </el-dialog>

    <el-dialog title="保存设置" :visible.sync="presetsNameVisible" width="20%" :before-close="handleClose">
      <div class="export-dialog-subtitle">将当前的订单导出字段，字段排序保存为设置。</div>
      <el-input v-model="presets_name" placeholder="请输入视图名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="saveConfiguration">保存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable"
import { getEspierExportFieldPresets, getEspierExportFieldEresetsLists, deleteEspierExportFieldPresets } from '@/api/trade'
export default {
  props: {
    title: {
      type: String,
      default: '导出'
    },
    subtitle: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    leftData: {
      type: Array,
      default: []
    },
    rightData: {
      type: Array,
      default: []
    },
    saveDataAction: {
      type: Function,
      default: null
    },
    closeDialog: {
      type: Function,
      default: null
    },
    selectAll: {
      type: Boolean,
      default: false
    },
    exportFunctionType: {
      type: String,
      default: ''
    },
    exportListData: {
      type: Array,
      default: []
    }
  },
  components: { draggable },
  data() {
    return {
      leftkeywords: '',
      rightkeywords: '',
      leftFilterList: [],
      rightFilterList: [],
      leftList: [],
      rightList: [],
      presetsNameVisible: false,
      presets_name: '',
      eresets_lists: [],
      eresetsItemId: ''
    }
  },
  methods: {
    closeDialogChange() {
      this.leftList = []
      this.rightList = []
      this.leftFilterList = []
      this.rightFilterList = []
      this.leftkeywords = ''
      this.rightkeywords = ''
      this.$emit('closeDialog')
    },
    saveDataActionChange() {
      this.$emit('saveDataAction', this.leftList)
    },
    leftInputChange(value) {
      this.rightkeywords = ''
      if (value) {
        this.leftFilterList = this.leftList.filter(item => item.name_cn.indexOf(value) > -1)
      } else {
        this.leftFilterList = []
      }
    },
    rightInputChange(value) {
      this.leftkeywords = ''
      if (value) {
        this.rightFilterList = this.rightList.filter(item => item.name_cn.indexOf(value) > -1)
      } else {
        this.rightFilterList = []
      }
    },
    leftDelChange(item, index) {
      const findObj = this.leftList.find(obj => obj.name_en == item.name_en)
      this.leftList = this.leftList.filter(obj => obj.name_en != item.name_en)
      this.leftFilterList = this.leftFilterList.filter(obj => obj.name_en != item.name_en)
      this.rightList.push(findObj)
    },
    rightAddChange(item, index) {
      const findObj = this.rightList.find(obj => obj.name_en == item.name_en)
      this.rightList = this.rightList.filter(obj => obj.name_en != item.name_en)
      this.rightFilterList = this.rightFilterList.filter(obj => obj.name_en != item.name_en)
      this.leftList.push(findObj)
    },
    onSelectAllChange() {
      if (this.rightkeywords) {
        this.rightList = this.rightList.filter(obj1 => !this.rightFilterList.find(obj2 => obj2.name_en == obj1.name_en))
        this.leftList.push(...this.rightFilterList)
        this.rightFilterList = []
      } else {
        this.leftList.push(...this.rightList)
        this.rightList = []
      }
    },
    onEnd(event) {
      const newObj = this.leftList[event.oldIndex]
      this.leftList.splice(event.oldIndex, 1)
      this.leftList.splice(event.newIndex, 0, newObj)
    },
    handleClose() {
      this.presets_name = ''
      this.presetsNameVisible = !this.presetsNameVisible
    },
    espierExportFieldEresetsLists() {
      // 获取导出字段预设列表
      let params = {
        page: 1,
        page_size: -1,
        export_type: this.exportFunctionType == 'normal_master_order' ? 'master_order' : 'order',
        preset_name: ''
      }
      getEspierExportFieldEresetsLists(params).then(res => {
        const { list } = res.data.data
        if (!list.length || list.length == 0) {
          this.eresetsItemId = ''
          this.eresets_lists = []
          this.leftList = this.leftData
          this.rightList = this.rightData
        } else {
          this.eresets_lists = list || []
          this.changeSelect(list[0].id)
        }
      })
    },
    deleteConfiguration() {
      // 删除当前视图
      deleteEspierExportFieldPresets({ id: this.eresetsItemId }).then(res => {
        const { success } = res.data.data
        if (success) {
          this.$message.success('已删除');
          this.espierExportFieldEresetsLists()
        }
      })
    },
    updataConfiguration() {
      // 更新视图设置
      let item = this.eresets_lists.find(el => el.id == this.eresetsItemId)
      const { id, export_type, presets_name } = item
      let params = {
        export_type,
        presets_name,
        id
      }
      this.espierExportFieldPresets(params)
    },
    saveConfiguration() {
      if (!this.presets_name) {
        return this.$message.error('请输入视图名称');
      }
      // 保存视图设置
      let params = {
        export_type: this.exportFunctionType == 'normal_master_order' ? 'master_order' : 'order',
        presets_name: this.presets_name
      }
      this.espierExportFieldPresets(params)
      this.handleClose()
    },
    espierExportFieldPresets(data) {
      const newFilterList = this.leftList.map(el => el.name_en)
      let params = {
        filed_list: JSON.stringify(newFilterList),
        ...data
      }
      getEspierExportFieldPresets(params).then(res => {
        const { id } = res.data.data
        if (id) {
          if (params.id) {
            this.$message.success('已更新');
          }
          this.espierExportFieldEresetsLists()
        }
      })

    },
    changeSelect(v) {
      let item = this.eresets_lists.find(el => el.id == v)
      const { id, filed_list, export_type, } = item
      let filedList = JSON.parse(filed_list)
      let left = []
      let right = []
      filedList.forEach((element) => {
        let index = this.exportListData.findIndex(el => el.name_en == element)
        if (index != -1) {
          left.push(this.exportListData[index])
        }
      })
      this.exportListData.forEach((element) => {
        let index = filedList.indexOf(element.name_en)
        if (index == -1) {
          right.push(element)
        }
      })
      this.eresetsItemId = id
      this.leftList = left
      this.rightList = right
    }
  },
  watch: {
    leftData(newVal, oldVal) {
      this.leftList = newVal
    },
    rightData(newVal, oldVal) {
      this.rightList = newVal
    },
    visible(newVal, oldVal) {
      if (newVal) {
        this.espierExportFieldEresetsLists()
      }
    }
  }
}
</script>

<style lang="scss">
.export-dialog {
  &-subtitle {
    font-size: 14px;
    color: #999;
  }

  &-body {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    .left,
    .right {
      width: 48%;
      border: 1px solid rgba(228, 231, 237, 1);
      height: 400px;
      border-radius: 5px;
      overflow: hidden;

      .title-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 15px;
        background: rgba(245, 245, 245, 1);
        border-bottom: 1px solid rgba(228, 231, 237, 1);

        .title {
          font-size: 16px;
          color: #666666;
        }
      }

      .export-input {
        margin: 10px 15px;
        display: flex;
        justify-content: space-between;

        .el-input__inner {
          border-radius: 50px;
          padding-left: 40px;
        }

        .el-icon-search {
          font-size: 18px;
          padding-left: 10px;
        }
      }

      .select-all-btn {
        margin-left: 5px;
        padding: 0px;
      }

      .left-list,
      .right-list {
        height: 290px;
        overflow-y: scroll;
        padding: 10px 15px;
        padding-right: 25px;

        .icon-bars {
          font-size: 16px;
          color: rgba(204, 204, 204, 1);
          margin-right: 10px;
        }

        .li-view {
          padding: 5px 0px;
          display: flex;
          align-items: center;
          color: #666666;
          display: flex;
          justify-content: space-between;
          align-items: center;
        }

        .el-icon-remove,
        .el-icon-circle-plus {
          font-size: 18px;
        }
      }

      .right-list {
        padding-left: 25px;
      }
    }
  }

  .el-dialog__header {
    padding-left: 30px;
  }

  .el-dialog__body {
    padding-top: 0px;
    padding: 0px 30px 20px;
  }

  .chosenClass {
    background: rgba(245, 245, 245, 1);
  }

  .dialog-footer {
    display: flex;
    justify-content: space-between;
  }
}
</style>
